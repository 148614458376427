import "./App.css";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Introduction from "./components/Introduction";
import Products from "./components/Products";
import AboutUs from "./components/AboutUs";

function App() {
  return (
    <>
      <Header />
      <Introduction />
      <Products />
      <AboutUs />
      <Footer />
    </>
  );
}

export default App;
