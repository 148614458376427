import React from "react";
import style from "./AboutUs.module.css";
import store from "../asset/images/products/PVC.jpg";
import phone from "../asset/images/icons/phone-black.svg";
import location from "../asset/images/icons/map-black.svg";

const AboutUs = () => {
  return (
    <div id="about-us" className={style.container}>
      <h2>درباره ی ما</h2>
      <div className={style.subContainer}>
        <img src={store} alt="فروشگاه" />
        <div className={style.content}>
          <p className={style.description}>
            پخش امین با سه دهه سابقه در زمینه پخش عمده، واردات و توزیع به صورت
            تخصصی انواع طلق و کفپوش در بازار تهران فعالیت میکند. همچنین این
            فروشگاه با شرکت ها و ارگان های به خصوص نظیر بیمارستان ها و ارگان های
            دولتی دیگر همکاری داشته.
          </p>
          <div className={style.contactUs} id="contact-us">
            <p
              style={{ lineHeight: "50px", fontWeight: "bold" }}
              className={style.description}
            >
              جهت سفارش با شماره های زیر در تماس باشید:
            </p>
            <p
              className={style.description}
              style={{
                color: "rgb(111 110 110)",
                fontSize: "0.8rem",
              }}
            >
              (فروش فقط و فقط به صورت عمده)
            </p>
            <p className={style.description}>
              <img className={style.icon} src={phone} alt="تلفن" />
              <span style={{ fontWeight: "600" }}>تلفن: </span>{" "}
              <a href="tel:+982155582640">02155582640</a> -
              <a href="tel:+982155582628">02155582628</a>
            </p>
            <p className={style.description}>
              <img className={style.icon} src={phone} alt="موبایل" />
              <span style={{ fontWeight: "600" }}>موبایل: </span>{" "}
              <a href="tel:+989901262307">09901262307</a>
              {"  "}
              (فقط شبکه های اجتماعی)
            </p>
            <p className={style.description}>
              <img className={style.icon} src={location} alt="موبایل" />
              <span style={{ fontWeight: "600" }}>آدرس فروشگاه:</span> تهران،
              خیابان پانزده خرداد، روبروی بانک صادرات، سرای محمدی، پلاک 10 (پخش
              امین)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
