import style from "./Introduction.module.css";
import kafpoosh from "../asset/images/slider/kafpoosh.jpg";
import talq from "../asset/images/slider/talgh.jpg";
import tokabineti from "../asset/images/slider/tookabineti.jpg";
import sofre from "../asset/images/slider/sofre.jpg";
const Introduction = () => {
  return (
    <>
      <div className={style.container}>
        <h1>پخش امین</h1>
        <p>پخش عمده انواع</p>
        <div className={style.subcontainer}>
          <div style={{paddingTop:"-15vh"}} className={style.slider}>
            <img   src={talq} alt={"طلق"} />
            <p>طلق</p>
          </div>
          <div style={{paddingTop:"15vh"}} className={style.slider}>
            <img src={sofre} alt={"سفره"} />
            <p>سفره</p>
          </div>
          <div style={{paddingTop:"15vh"}}  className={style.slider}>
            <img src={kafpoosh} alt={"کفپوش"} />
            <p>کفپوش</p>
          </div>
          <div style={{paddingTop:"-15vh"}} className={style.slider}>
            <img  src={tokabineti} alt={"توکابینتی"} />
            <p>توکابینتی</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Introduction;
