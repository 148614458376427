import style from "./Footer.module.css";
import React from "react";
import whatsapp from "../../asset/images/icons/whatsapp.svg";
import telegram from "../../asset/images/icons/telegram.svg";
import instagram from "../../asset/images/icons/instagram.svg";

import phone from "../../asset/images/icons/phone-white.svg";
import location from "../../asset/images/icons/map-white.svg";

const Footer = () => {
  return (
    <div className={style.container}>
      <div className={style.subContainer}>
        <div className={style.firstContent}>
          <p style={{ whiteSpace: "nowrap" }}>
            <img className={style.icon} src={phone} alt="تلفن" />
            تلفن: <a href="tel:+982155582640">02155582640</a> -{" "}
            <a href="tel:+982155582628">02155582628</a>
          </p>
          <p>
            <img className={style.icon} src={phone} alt="موبایل" />
            موبایل: <a href="tel:+989901262307">09901262307</a>
          </p>
          <p>
            <img className={style.icon} src={location} alt="آدرس" />
            آدرس فروشگاه: تهران، خیابان پانزده خرداد، روبروی بانک صادرات، سرای
            محمدی، پلاک 10 (پخش امین)
          </p>
        </div>
        <div className={style.secondContent}>
          <p className={style.introduction}>
            <span>ساعات کاری: </span>
            <br />
            شنبه تا چهاشنبه: 8:00 تا 18:00 پنجشنبه: 8:00 تا 14:00
          </p>
          <div className={style.bottomNav}>
            <ul>
              <li>
                <a href="/">خانه</a>
              </li>
              <li>
                <a href="#products">محصولات</a>
              </li>
              <li>
                <a href="#about-us">درباره ما</a>
              </li>
              <li>
                <a href="#contact-us">تماس با ما</a>
              </li>
            </ul>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d405.1266455734678!2d51.42346087382393!3d35.67667784027561!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f8e01009a50a39b%3A0x7ab04a56e4ceb5f6!2z2b7Yrti0INin2YXbjNmGIC0gcGFraHNoIEFtaW4!5e0!3m2!1sen!2suk!4v1691855014351!5m2!1sen!2suk"
            className={style.map}
            title="location"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      <div className={style.socialMedia}>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://api.whatsapp.com/send?phone=989901262307"
        >
          <img src={whatsapp} alt="whatsApp" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://t.me/Pakhsh_amin_admin"
        >
          <img src={telegram} alt="telegram" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://instagram.com/pakhsh.amin"
        >
          <img src={instagram} alt="instagram" />
        </a>
      </div>
      <div className={style.creatorSection}>
        <p>
          Site designed by{" "}
          <a href="https://devvard.com">
            DevvarD
          </a>
          <span> ©</span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
