import style from "./Header.module.css";
import React from "react";
import Logo from "../../asset/images/Logo.png";

const Header = () => {
  return (
    <>
      <div className={style.container}>
        <a href="/">
          <img className={style.logo} src={Logo} alt="Logo" />
        </a>
        <div className={style.topNav}>
          <ul>
            <li>
              <a href="/">خانه</a>
            </li>
            <li>
              <a href="#products">محصولات</a>
            </li>
            <li>
              <a href="#about-us">درباره ما</a>
            </li>
            <li>
              <a href="#contact-us">تماس با ما</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
