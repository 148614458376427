import React from "react";
import style from "./Products.module.css";
import talq from "../asset/images/products/talq.webp";
import kafpoosh from "../asset/images/products/kafposh.jpg";
import sofre from "../asset/images/products/sofre.jpg";
import tokabineti from "../asset/images/products/tokabineti.jpg";
import rokeshchasbi from "../asset/images/products/rokesh-chasbi.jpg";

const Products = () => {
  return (
    <div className={style.container} id="products">
      <h2 className={style.head}>محصولات ما</h2>

      <div className={style.right}>
        <img src={talq} alt="طلق" />
        <div className={style.description}>
          <h3>طلق</h3>
          <p>انواع طلق های ایرانی و خارجی، نرم وشفاف در عرض های:</p>{" "}
          <ul>
            <li>100 cm</li>
            <li>140 cm</li>
            <li>160 cm</li>
            <li>180 cm</li>
            <li>210 cm</li>
          </ul>
        </div>
      </div>

      <div className={style.left}>
        <img src={sofre} alt="سفره" />
        <div className={style.description}>
          <h3>سفره</h3>
          <p>انواع سفره های ایرانی و خارجی در طرح های مختلف:</p>{" "}
          <ul>
            <li>یک رو</li>
            <li>دو رو</li>
            <li>پشت رزین</li>
            <li>طلق چاپدار</li>
            <li>پشت پارچه</li>
          </ul>
        </div>
      </div>

      <div className={style.right}>
        <img src={rokeshchasbi} alt="روکش چسبی" />
        <div className={style.description}>
          <h3>روکش چسبی</h3>
          <p>انواع روکش های چسبی خارجی پشت چسبدار:</p>{" "}
          <ul>
            <li>اکلیلی</li>
            <li>متال ضخیم</li>
            <li>شبرنگ</li>
            <li>هایگلاس</li>
            <li>شیشه مات کن</li>
            <li>کاغذ دیواری چسبدار</li>
          </ul>
        </div>
      </div>

      <div className={style.left}>
        <img src={kafpoosh} alt="کفپوش" />
        <div className={style.description}>
          <h3>کفپوش</h3>
          <p>انواع کفپوش های ایرانی و خارجی در ضخامت ها و عرض های مختلف:</p>
          <ul className={style.desc}>
            <li>50 cm</li>
            <li>60 cm</li>
            <li>70 cm</li>
            <li>80 cm</li>
            <li>90 cm</li>
            <li>100 cm</li>
            <li>120 cm</li>
            <li>160 cm</li>
            <li>170 cm</li>
            <li>200 cm</li>
          </ul>
        </div>
      </div>

      <div className={style.right}>
        <img src={tokabineti} alt="توکابینتی" />
        <div className={style.description}>
          <h3>توکابینتی</h3>

          <p>انواع توکابینتی های ساده و چاپدار و شیشه ای در عرض های 50 و 60</p>
        </div>
      </div>
    </div>
  );
};

export default Products;
